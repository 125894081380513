/* ================ CSS Queries to apply from a width and scape up ================ */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* ================ CSS Queries to apply from a width and scale down ================ */

/* Extra small devices (portrait phones, 576px and down) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, 768px and down) */
@media (max-width: 767.98px) {
	.hide-if-mobile {
		display: none;
	}
}

/* Medium devices (tablets, 992px and down) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, 1200px and down) */
@media (max-width: 1199.98px) {
}

/* ================ CSS Queries to apply from a width with no scale ================ */

/* Extra small devices (portrait phones only, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones only, 576px) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets only, 768px) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops only, 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

.stickit {
	position: fixed;
	top: 0;
	z-index: 1;
}

.relative {
	position: relative;
}

.card-img-bottom {
	color: #fff;
	height: 20rem;
	background: url("../images/services/fono.jpg") center no-repeat;
	background-size: cover;
}

.hide {
	display: none;
}

#div_loading {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}

#div_loadingImg {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 50px;
	color: white;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}
