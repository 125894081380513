@media (width <= 767.98px) {
  .hide-if-mobile {
    display: none;
  }
}

.stickit {
  z-index: 1;
  position: fixed;
  top: 0;
}

.relative {
  position: relative;
}

.card-img-bottom {
  color: #fff;
  height: 20rem;
  background: url("fono.93ba1f7a.jpg") center / cover no-repeat;
}

.hide {
  display: none;
}

#div_loading {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #00000080;
  position: fixed;
  inset: 0;
}

#div_loadingImg {
  color: #fff;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*# sourceMappingURL=index.75281962.css.map */
